import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import CarouselNavigation from '@components/carousels/carouselNavigation';

import { ModuleWrapper } from '../moduleWrapper';
import { FeatureCard } from '@components/cards/featureCard';

const CardCarousel = ({ config, title, items }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    align: 'start',
    containScroll: true,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);

  const scrollPrev = React.useCallback(
    () => embla && embla.scrollPrev(),
    [embla]
  );

  const scrollNext = React.useCallback(
    () => embla && embla.scrollNext(),
    [embla]
  );

  const onSelect = React.useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  React.useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <ModuleWrapper {...config}>
      <div className='px-gutter py-16 overflow-hidden'>
        <h3 className='blockH1 text-center mb-one md:mb-three'>{title}</h3>
        <div className='relative w-full group'>
          <div ref={viewportRef}>
            <div className='flex gap-x-2 md:gap-x-4'>
              {items.map((item, index) => (
                <div
                  className='flex-shrink-0 w-4/5 md:w-1/2 lg:w-1/3'
                  key={index}
                >
                  <FeatureCard {...item} aspectRatio='portrait' />
                </div>
              ))}
            </div>
          </div>
          <CarouselNavigation
            prevBtnEnabled={prevBtnEnabled}
            nextBtnEnabled={nextBtnEnabled}
            scrollPrev={scrollPrev}
            scrollNext={scrollNext}
            centerArrows={true}
          />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default CardCarousel;
